import "./editDevice.styles.css";
import React, { useState, useEffect } from "react";
import { Checkbox } from "@alpaca/framework";
import { getAllProfile } from "../../../../service/profile/profile";

function UserList(props) {
  const [profiles, setProfiles] = useState(null);
  const [device, setDevice] = useState(props.device);

  useEffect(() => {
    getAllProfile(props.userInfo?.company_name)
      .then((data) => setProfiles(data))
      .catch((error) => {
        if (props.openToast) {
          props.openToast({
            title: "Get Profile List fail!",
            description: error.message,
            duration: 5,
            status: "error",
          });
        }
      });
  }, []);

  const isChecked = (profileName) => {
    if (device?.profile) {
      return device.profile.find(
        (profile) => profile.profile_name === profileName
      );
    } else return false;
  };

  const HandleCheckBoxChange = (event) => {
    // console.log(event.target.checked)
    // props.onData(event.target.value,event.target.checked)
    if (event.target.checked === false) {
      let findIndex = device.profile.findIndex(
        (obj) => obj.profile_name === event.target.value
      );
      device.profile.splice(findIndex, 1);
      let newArray = device.profile;
      setDevice({ ...device, profile: newArray });
    } else {
      let newObj = { profile_name: event.target.value };
      device.profile.push(newObj);
      let newArray = device.profile;
      setDevice({ ...device, profile: newArray });
    }
    props.onData(device);
  };

  return (
    <div className="edit-device-list">
      {Array.isArray(profiles) > 0 ? (
        profiles.map((profile) => {
          if (profile.profile_status === "Invite") return <></>;
          return (
            <div className="edit-device-list-item">
              <Checkbox
                defaultChecked={isChecked(profile.profile_name)}
                onChange={HandleCheckBoxChange}
                value={profile.profile_name}
              />
              <h3>{profile.profile_name}</h3>
            </div>
          );
        })
      ) : (
        <>no users to display</>
      )}
    </div>
  );
}

export default UserList;

{
  /* <div>
{console.log(profiles)}
<div className="edit-device-list">
	<div className="edit-device-list-item">
		<Checkbox defaultChecked="true" />
		<h3>Antonio</h3>
	</div>
	<div className="edit-device-list-item">
		<Checkbox defaultChecked="true" />
		<h3>devuser</h3>
	</div>
	<div className="edit-device-list-item">
		<Checkbox defaultChecked="true" />
		<h3>Evanm</h3>
	</div>
	<div className="edit-device-list-item">
		<Checkbox defaultChecked="true" />
		<h3>gcairns</h3>
	</div>
	<div className="edit-device-list-item">
		<Checkbox defaultChecked="true" />
		<h3>jeffjolliffe</h3>
	</div>
	<div className="edit-device-list-item">
		<Checkbox defaultChecked="true" />
		<h3>lphasiri</h3>
	</div>
	<div className="edit-device-list-item">
		<Checkbox defaultChecked="true" />
		<h3>MRichardson</h3>
	</div>
	<div className="edit-device-list-item">
		<Checkbox defaultChecked="true" />
		<h3>raman</h3>
	</div>
</div>
</div> */
}
