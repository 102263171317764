import "./editDevice.styles.css";
import React, { useState, useEffect } from "react";
import { Input, Icon } from "@alpaca/framework";
//import { getAllModules } from "../../../../service/ModuleService";
import TestIcon from "../../../../assets/icons/moduleIcons/Shipping.svg";
import TestBackground from "../../../../assets/Img/Modules/placeholder.jpg";

function AppList(props) {
  const [apps, setApps] = useState(props.modules);
  const [device, setDevice] = useState(props.device);
  const [search, setSearch] = useState("");
  // useEffect(()=>{
  // 	getAllModules().then((data) =>setApps(data) )
  // 	;
  // },[])

  const isChecked = (appName) => {
    if (device.appUsed) {
      return device.appUsed.find((app) => app.app_name === appName);
    } else return false;
  };

  const HandleCheckBoxChange = (event) => {
    // console.log(event.target.checked)
    // props.onData(event.target.value,event.target.checked)
    if (event.target.checked === false) {
      let findIndex = device.appUsed.findIndex(
        (obj) => obj.app_name === event.target.value
      );
      device.appUsed.splice(findIndex, 1);
      let newArray = device.appUsed;
      setDevice({ ...device, appUsed: newArray });
    } else {
      let newObj = { app_name: event.target.value };
      device.appUsed.push(newObj);
      let newArray = device.appUsed;
      setDevice({ ...device, appUsed: newArray });
    }
    props.onData(device);
  };

  return (
    <div>
      <Input
        className="searchbar mb20"
        size="large"
        placeholder="Search"
        onChange={(e) => setSearch(e.target.value)}
        prefix={<Icon icon="search" />}
      />
      <div className="app-list">
        {apps ? (
          apps.map((app) => {
            console.log(search);
            if (!app.app_name.toLowerCase().includes(search)) return <></>;
            const background = app.app_background;
            const icon = app.app_icon;
            const inList = !!device.app_used.find(
              (e) => e.app_name === app.app_name
            );
            return (
              <div className="edit-device-list-item">
                <div className={`app-list-item ${inList && "app-selected"}`}>
                  <img
                    src={
                      background.startsWith("data")
                        ? background
                        : `data:image/jpeg;base64,${background}`
                    }
                    alt="Base64 JPG -100"
                    title=""
                    className="app-list-background"
                  />
                  <div className="app-list-title headline">
                    <br />
                    {app.app_name}
                  </div>
                  <img
                    src={`data:image/svg+xml;base64,${app?.app_icon}`}
                    alt="Base64 SVG"
                    title=""
                    className="app-list-icon"
                  />
                </div>
              </div>
            );
          })
        ) : (
          <>no apps to display</>
        )}

        <div className="app-list-item">
          <img
            src={TestBackground}
            alt="Base64 JPG -100"
            title=""
            className="app-list-background"
          />
          <div className="app-list-title headline">
            <br />
            App Title
          </div>
          <img
            src={TestIcon}
            alt="Base64 SVG"
            title=""
            className="app-list-icon"
          />
        </div>
      </div>
    </div>
  );
}

export default AppList;

{
  /* <div className="edit-device-list">
				<div className="edit-device-list-item">
					<Checkbox defaultChecked="true" />
					<h3>Power Monitor</h3>
				</div>
				<div className="edit-device-list-item">
					<Checkbox defaultChecked="true" />
					<h3>Sales History & Analytics</h3>
				</div>
				<div className="edit-device-list-item">
					<Checkbox defaultChecked="true" />
					<h3>Sales Order Status Board</h3>
				</div>
				<div className="edit-device-list-item">
					<Checkbox defaultChecked="true" />
					<h3>Sales Quote - New Item</h3>
				</div>
				<div className="edit-device-list-item">
					<Checkbox defaultChecked="true" />
					<h3>Shop Floor - Routing</h3>
				</div>
				<div className="edit-device-list-item">
					<Checkbox defaultChecked="true" />
					<h3>Shop Floor - Shipping</h3>
				</div>
				<div className="edit-device-list-item disallowed-text">
					<Checkbox disabled="true" />
					<h3>Shop Floor Assembly</h3>
				</div>
			</div> */
}
