import React from "react";
import { Modal, Form } from "@alpaca/framework";
import UserList from "../EditDevice/userList.component";
import AppList from "../EditDevice/appList.component";
export default function EditDeviceModal({
  open,
  onCancel,
  modules,
  device,
  ...props
}) {
  function updateDeviceProfiles(data) {
    console.log(data);
  }

  return (
    <Modal {...props} title="Edit Device" open={open} onCancel={onCancel}>
      <div style={{ display: "grid", gridTemplateColumns: "80% auto" }}>
        <AppList modules={modules} device={device} />
        <UserList
          userInfo={{ company_name: "Steelhaus Technologies Inc." }}
          device={device}
          openToast={() => {
            console.log("toast");
          }}
          onData={updateDeviceProfiles}
        />{" "}
      </div>
    </Modal>
  );
}
