import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Icon, Table, TableHeader } from "@alpaca/framework";
import { ClassSelector } from "../../../store/reduxSelector";
import ModulesHeaderImg from "./../../../assets/Img/Modules/modules-header-img.svg";

import columns from "./devices.columns";
import EditDeviceModal from "./EditDeviceModal/EditDeviceModal.component";

import {
  getAllDevices,
  getDeviceCookie,
  getCurrentDevice,
} from "../../../service/devices/deviceServices";
import { getAllModules } from "../../../service/ModuleService";

import "./devicesPage.styles.css";

function DevicesPage(props) {
  const { userInfo } = props;

  // states
  const [devices, setDevices] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingDevice, setEditingDevice] = useState({});
  const [modules, setModules] = useState([]);
  const [currentDevice, setCurrentDevice] = useState(null);

  // lifecycles
  useEffect(() => {
    getCurrentDevice()
      .then((result) => setCurrentDevice(result))
      .catch(() => setCurrentDevice(null));

    getAllDevices(userInfo?.company_name)
      .then((result) => setDevices(result))
      .catch((error) => console.log(error));

    getAllModules()
      .then((data) => {
        setModules(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function setAsCurrentDevice({ device_id }) {
    getDeviceCookie(device_id);
    setCurrentDevice(device_id);
  }

  function editDevice(device) {
    setEditingDevice(device);
    setShowEditModal(true);
  }

  return (
    <div className="devicesCollapse">
      <section className="devices-header">
        <h1 className="devices-welcome-headline">Devices</h1>
        <img
          src={ModulesHeaderImg}
          alt="dashboard modules graphic"
          className="modules-header-img"
        />
      </section>
      <div className="devices-page">
        <TableHeader
          title=""
          showOptions={false}
          extraComponent={
            <Link to="/dashboard/NewDevice" className="device-settings-btn">
              <button className="main device-settings-btn">
                <Icon icon="plus" className="alttext" /> Add Device
              </button>
            </Link>
          }
        />
        <Table
          columns={columns({
            modules: modules,
            onEdit: editDevice,
            setAsCurrentDevice: setAsCurrentDevice,
            currentDevice: currentDevice,
            onDelete: () => {},
          })}
          dataSource={devices}
          pagination={false}
          className="settings-table"
        />
      </div>
      <EditDeviceModal
        open={showEditModal}
        device={editingDevice}
        modules={modules}
        onCancel={() => setShowEditModal(false)}
      />
    </div>
  );
}

const stateList = ["userInfo"];
export default connect((state) => ClassSelector(state, stateList))(DevicesPage);
