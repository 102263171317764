import axios from "axios";
import config from "../../config";
const ROUTER_NAME = "devices";
const { handleAxiosError } = require("../error.handler.service");
export const getAllDevices = async (company_name) => {
  try {
    const resp = await axios.get(`${config.IP_ADDRESS}/${ROUTER_NAME}`, {
      params: {
        company_name: company_name,
      },
    });
    return resp.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const getCurrentDevice = async () => {
  try {
    const resp = await axios.get(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/currentDevice`
    );
    return resp.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const getDeviceCookie = async (device_id) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/assign`,
      {
        device_id,
      }
    );
    return resp.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const deleteDevice = async (device) => {
  try {
    let deviceId = device.device_id;
    const resp = await axios.delete(`${config.IP_ADDRESS}/${ROUTER_NAME}`, {
      data: { deviceId },
    });

    return resp.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const updateDevice = async (deviceUpdateData) => {
  try {
    let data = {
      device_id: deviceUpdateData.device_id,
      device_name: deviceUpdateData?.device_name,
      appUsed: deviceUpdateData.appUsed.map((app) => {
        return { app_name: app.app_name };
      }),
      profiles: deviceUpdateData.profile.map((profile) => {
        return { profile_name: profile.profile_name };
      }),
    };
    const resp = await axios.post(`${config.IP_ADDRESS}/${ROUTER_NAME}`, data);
    return resp.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};
export const addNewDevice = async (newDeviceData) => {
  try {
    const resp = await axios.post(`${config.IP_ADDRESS}/${ROUTER_NAME}/add`, {
      newDeviceData,
    });
    return resp.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};
